import 'lib/polyfills';
import '../styles/global.css';
import 'react-image-crop/dist/ReactCrop.css';
import 'react-toastify/dist/ReactToastify.css';
import '@internal/ui/reach-styles';
import { ApiProvider } from '@internal/api-hooks/context';
import { hideAccountPanel } from '@internal/state/accountPanel';
import ErrorFallback from 'components/ErrorFallback';
import { AppLayoutProvider } from 'context/appLayout';
import { TermsOfServiceModals } from 'features/tos/TermsOfServiceModals';
import { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { ReactNode, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import { setNavigating, track } from 'state/app';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { wrapper } from 'state/store';
import { API_HOST } from 'typescript/api';
import { AppComponent } from 'typescript/typings';
import { ignoreWhitelistedErrors } from 'utils/errors';
import { AppLoader } from '../components/AppLoader';
import { OVERLAY_ROOT_ID } from '../components/OverlayPortal';
import AuthProvider from '../features/auth/context';
import usePageView from '../features/tracking/usePageView';
import useFirstUserInteraction from '../hooks/useFirstUserInteraction';
import useIdle from '../hooks/useIdle';
import redirects from '../routing/redirects';

const DynamicAuth = dynamic(() => import('features/auth/Auth'), { ssr: false });

interface IApp extends AppProps {
    Component: AppComponent<any>;
    pageProps: any;
}

const PlaybackApp: React.FunctionComponent<IApp> = ({ Component, pageProps, ...rest }) => {
    const Layout = Component.Layout || React.Fragment;
    const router = useRouter();

    const dispatch = useAppDispatch();
    const loaderVisible = useAppSelector((state) => state.app.loaderVisible);
    const accessToken = useAppSelector((state) => state.user.accessToken);
    const authRedirect = redirects[router.pathname];

    const [queryClient] = useState(() => new QueryClient());

    useIdle();
    usePageView();
    useFirstUserInteraction();

    useEffect(() => {
        ignoreWhitelistedErrors();

        const handleRouteChangeStart = () => {
            dispatch(hideAccountPanel());
        };

        const handleRouteChangeComplete = () => {
            dispatch(setNavigating(false));
        };

        router.events.on('routeChangeStart', handleRouteChangeStart);
        router.events.on('routeChangeComplete', handleRouteChangeComplete);

        return () => {
            router.events.off('routeChangeStart', handleRouteChangeStart);
            router.events.off('routeChangeComplete', handleRouteChangeComplete);
        };
    }, [dispatch, router]);

    useEffect(() => {
        const handleUnload = () => {
            dispatch(track({ event: 'Close Window' }));
        };

        window.addEventListener('beforeunload', handleUnload);

        return () => {
            window.removeEventListener('beforeunload', handleUnload);
        };
    }, [dispatch]);

    return (
        <>
            <Head>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=1, viewport-fit=cover"
                />
                <meta
                    name="facebook-domain-verification"
                    content="3zw4n8y0j5vammwk0sr30vefetni9l"
                />
            </Head>
            <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onError={async (error) => {
                    dispatch(
                        track({
                            event: 'Error Caught at Boundary',
                            errorMessage: error.message,
                            errorName: error.name,
                            errorStack: error.stack,
                        })
                    );

                    const Sentry = await import('@sentry/nextjs');
                    Sentry.captureException(error, {
                        tags: {
                            appContext: 'errorBoundary',
                        },
                    });
                }}
            >
                <AppLayoutProvider>
                    <QueryClientProvider client={queryClient}>
                        <Hydrate state={pageProps.dehydratedState}>
                            <AppApiProvider>
                                <AuthProvider>
                                    <Layout>
                                        <Component {...pageProps} {...rest} />
                                        {!accessToken && (
                                            <DynamicAuth
                                                redirectOnLogin={
                                                    !!authRedirect?.to && authRedirect?.loggedIn
                                                }
                                            />
                                        )}
                                        <AppLoader visible={loaderVisible} />
                                        <div id={OVERLAY_ROOT_ID} />
                                        <TermsOfServiceModals />
                                    </Layout>
                                </AuthProvider>
                            </AppApiProvider>
                        </Hydrate>
                    </QueryClientProvider>
                </AppLayoutProvider>
            </ErrorBoundary>
        </>
    );
};

const AppApiProvider = (props: { children: ReactNode }) => {
    const accessToken = useAppSelector((state) => state.user.accessToken);

    return (
        <ApiProvider host={API_HOST} token={accessToken}>
            {props.children}
        </ApiProvider>
    );
};

export default wrapper.withRedux(PlaybackApp);
